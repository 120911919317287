/* html,
body {
    height: 100%;
}

.thankyou_page {
    background-color: #f2f2f2;
}

.thank-page-template {
    background-color: #f2f2f2;
}

.thank-you-wrapper {
    position: relative;
    height: 100%;
    text-align: center;
}

.thank-you-wrapper>.container {
    width: 780px;
    margin: 0 auto;
}

.thank-you-page-content {
    position: relative;
    float: left;
    width: 100%;
    background: #f2f2f2;
    padding: 90px;
    margin: 30px 0;
    box-sizing: border-box;
}

.thank-you-page-content::before,
.thank-you-page-content::after {
    z-index: -1;
    position: absolute;
    content: "";
    bottom: 15px;
    left: 10px;
    width: 50%;
    top: 80%;
    max-width: 300px;
    background: #777;
    box-shadow: 0 15px 10px #777;
    transform: rotate(-3deg);
}

.thank-you-page-content::after {
    transform: rotate(3deg);
    right: 10px;
    left: auto;
}

html body .thank-you-wrapper .container {
    display: table;
    height: 100%;
}

html body .thank-you-wrapper .container>.row {
    display: table-cell;
    height: 100%;
    vertical-align: middle;
}

.thank-you-page-logo {
    float: left;
    width: 100%;
}

.checkmark {
    font-size: 50px;
    color: green;
    height: 100px;
    width: 100px;
    line-height: 100px;
    border: 2px solid green;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    margin: 0 auto;
    position: relative;
    padding: 20px;
    margin-Bottom: 20px;
}

.message {
    text-align: center;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.footer {
    text-align: center;
    margin: 12px 0px;
}

.footer-link {
    color: #fff;
    text-Decoration: none;
    background-color: #54a2d9;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 20px;
}
.footerLinkHover{
    text-decoration: underline;
}
.pera{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.arrow-icon {
    position: relative;
    padding-left: 55px;
}

.arrow-icon::before {
    position: absolute;
    left: 25px;
    top: 20px;
    content: "";
    display: inline-block;
    box-sizing: border-box;
    height: 10px;
    width: 10px;
    border-style: solid;
    border-color: #fff;
    border-width: 0px 1px 1px 0px;
    transform: rotate(131deg);
    transition: border-width 150ms ease-in-out;
}

.arrow-icon::after {
    content: "";
    display: inline-block;
    width: 20px;
    background-color: #fff;
    height: 1px;
    position: absolute;
    left: 25px;
    top: 25px;
}

.thank-you-wrapper ul.footer-nav li a,
.thank-you-wrapper ul.footer-nav li a:hover {
    color: #0a568a;
}

.thank-you-wrapper ul.footer-nav li+li::before {
    background: rgba(0, 0, 0, 0.3);
}

.thank-you-copy p {
    margin: 0;
    padding: 0;
    font-size: 12px;
}

.thank-you-page-content h1 {
    position: relative;
    width: 100%;
    float: left;
    margin-bottom: 45px;
    padding-top: 110px;
    font-size: 30px;
    font-weight: 200;
    line-height: 40px;
}

.thank-you-page-content h1::before {
    content: "\\f00c";
    top: 0;
    transform: translateX(-50%);
    left: 50%;
    position: absolute;
    font-family: "FontAwesome";
    font-size: 60px;
    text-align: center;
    float: left;
    width: 100px;
    color: green;
    height: 100px;
    line-height: 100px;
    border: 2px solid green;
    border-radius: 100%;
}

.thank-you-page-content .btn {
    padding-top: 13px;
    padding-bottom: 13px;
    padding-right: 25px;
}

ul.footer-nav {
    text-align: center;
    margin: 12px 0;
}

ul.footer-nav li {
    display: inline-block;
    position: relative;
}

ul.footer-nav li+li {
    padding-left: 30px;
}

ul.footer-nav li+li::before {
    content: "";
    height: 12px;
    width: 1px;
    background: #fff;
    display: inline-block;
    position: absolute;
    top: 3px;
    left: 12px;
}

ul.footer-nav li p,
ul.footer-nav li {
    font-size: 12px;
    font-weight: 400;
}

ul.footer-nav li a {
    text-decoration: none;
}

ul.footer-nav li a,
ul.footer-nav li a:hover {
    color: #000;
}

ul.footer-nav li a:hover {
    color: #000;
    text-decoration: underline;
}

ul.footer-nav li p {
    line-height: normal;
}

.btn-primary {
    background-color: #0a568a;
    color: #fff;
    text-decoration: none;
    border-color: #0a568a;
    position: relative;
    padding-top: 13px;
    padding-bottom: 13px;
    padding-right: 25px;
}

.btn-primary::before {
    position: absolute;
    left: 25px;
    top: 20px;
    content: "";
    display: inline-block;
    box-sizing: border-box;
    height: 10px;
    width: 10px;
    border-style: solid;
    border-color: #fff;
    border-width: 0px 1px 1px 0px;
    transform: rotate(131deg);
    transition: border-width 150ms ease-in-out;
}

.btn-primary::after {
    content: "";
    display: inline-block;
    width: 20px;
    background-color: #fff;
    height: 1px;
    position: absolute;
    left: 25px;
    top: 25px;
} */


.thakyou_page{
    padding: 350px 0px;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: white;
    padding: 30px;
    border-radius: 8px;
    text-align: center;
    max-width: 500px;
    width: 90%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.checkmark {
    font-size: 50px;
    color: green;
    height: 100px;
    width: 100px;
    line-height: 100px;
    border: 2px solid green;
    border-radius: 50%;
    margin: 0 auto 20px auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* .title {
    font-size: 30px;
    margin-bottom: 20px;
} */

.message {
    margin-bottom: 20px;
}

.close-button {
    background-color: #54a2d9;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.close-button:hover {
    background-color: #0a568a;
}
