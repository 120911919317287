/* Alert styling */
.alert {
    padding: 15px;
    background-color: #4CAF50;
    color: white;
    margin-top: 15px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  /* Checkmark container */
  .checkmark {
    width: 40px;
    height: 40px;
    position: relative;
    display: inline-block;
    border-radius: 50%;
    background-color: white;
    transform: scale(1.2);
  }
  
  /* Circle around the checkmark */
  .checkmark-circle {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 4px solid #4CAF50;
    box-sizing: border-box;
  }
  
  /* Stem of the checkmark */
  .checkmark-stem {
    position: absolute;
    width: 6px;
    height: 15px;
    background-color: #4CAF50;
    top: 50%;
    left: 35%;
    transform-origin: bottom left;
    transform: rotate(45deg);
    animation: checkmark-animation 0.4s ease-in-out forwards;
  }
  
  /* Kick of the checkmark */
  .checkmark-kick {
    position: absolute;
    width: 3px;
    height: 8px;
    background-color: #4CAF50;
    top: 62%;
    left: 45%;
    transform-origin: bottom left;
    transform: rotate(-45deg);
    animation: checkmark-animation 0.4s ease-in-out forwards;
  }
  
  /* Animation for the checkmark */
  @keyframes checkmark-animation {
    from {
      opacity: 0;
      transform: scale(0);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  