.loader_box {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
}

.loader_image {
    width: 300px;
}

.loader_image img {
    width: 100%;
}

/* Wave Loader */
.loading-wave {
    width: 280px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.loading-bar {
    width: 15px;
    height: 10px;
    margin: 0 4px;
    background-color: #3498db;
    border-radius: 5px;
    animation: loading-wave-animation 1s ease-in-out infinite;
}

.loading-bar:nth-child(2) {
    animation-delay: 0.1s;
}

.loading-bar:nth-child(3) {
    animation-delay: 0.2s;
}

.loading-bar:nth-child(4) {
    animation-delay: 0.3s;
}

@keyframes loading-wave-animation {
    0% {
        height: 10px;
    }

    50% {
        height: 50px;
    }

    100% {
        height: 10px;
    }
}

/* Responsive Design */
@media (max-width: 1920px) {
    .loader_image {
        width: 400px;
    }
}

@media (max-width: 1400px) {
    .loader_image {
        width: 350px;
    }
}

@media (max-width: 1200px) {
    .loader_image {
        width: 300px;
    }

    .loading-wave {
        width: 250px;
        height: 70px;
    }
}

@media (max-width: 992px) {
    .loader_image {
        width: 280px;
    }

    .loading-wave {
        width: 220px;
        height: 60px;
    }
}

@media (max-width: 768px) {
    .loader_image {
        width: 240px;
    }

    .loading-wave {
        width: 200px;
        height: 50px;
    }
}

@media (max-width: 575px) {
    .loader_image {
        width: 200px;
    }

    .loading-wave {
        width: 180px;
        height: 40px;
    }
}

@media (max-width: 375px) {
    .loader_image {
        width: 160px;
    }

    .loading-wave {
        width: 150px;
        height: 35px;
    }
}

@media (max-width: 320px) {
    .loader_image {
        width: 140px;
    }

    .loading-wave {
        width: 120px;
        height: 30px;
    }
}
.loader {
    position: relative;
    height: 100%;
    width: 100%;
}