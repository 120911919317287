@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.data {
  padding: 200px 0px;
}

@media (min-width:1400px) and (max-width:1530px) {
  .images_data {
    width: 300px;
  }

  .card a {
    width: 300px !important;
  }

  .card .overlay {
    width: 300px;
  }
}

@media (max-width:1920px) {
  .images_data {
    width: 360px;
    /* height: 360px; */
  }
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  /* Limits text to 3 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (min-width:768px) and (max-width:1920px) {
  .classdata {
    margin-top: -400px;
  }
}
@media (min-width:1500px) and (max-width:1920px) {
  .review_content_2 {
    aspect-ratio: 3/2.8;
  }

  .review_content_2 p {
    display: -webkit-box;
    -webkit-line-clamp: 3 !important;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
/* Add this CSS in your global styles */
.screenshot-blocker {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.01);
  /* Nearly transparent overlay */
  pointer-events: none;
  z-index: 9999;
}
.logos {
  width: 75%;
  height: 100%;
  aspect-ratio: 16/9;
  object-fit: contain;
}